<template>
  <div id="app">
      <Header />
      <router-view />
      <Footer />
  </div>
</template>
 
<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  created(){
    const icon = document.createElement("link");
    icon.rel = "shortcut icon";
    icon.type = "image/x-icon";
    icon.href = "./assets/img/favicon.ico";
    document.head.appendChild(icon);
  },

}
</script>

<style>

</style>
