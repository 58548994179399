import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "../firebase/firebase"
import Login from '../views/Login.vue'
import Admin from '../views/Admin/Admin.vue'
import Blog from '../views/Blog.vue'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Portfalio from '../views/Portfalio.vue'
import BlogDetail from '../views/BlogDetail.vue'
import BlogAdmin from '../views/Admin/blogAdmin.vue'
import Preview from '../views/Live-Preview.vue'
import Workspace from '../views/Workspace.vue'
import Base64 from '../views/IMG-TO-BASE64.vue'
import TextToSpeech from '../views/TextToSpeech.vue'

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Home',
        component: Home

    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blogAdmin',
        name: 'blogAdmin',
        component: BlogAdmin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog,
    },
    {
        path: '/-:blogId',
        name: 'BlogDetail',
        component: BlogDetail,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/portfolio',
        name: 'Portfalio',
        component: Portfalio,
    },
    {
        path: '/about-me',
        name: 'About',
        component: About,
    },
    {
        path: '/live-preview',
        name: 'LivePreview',
        component: Preview,
    }, {
        path: '/workspace',
        name: 'Workspace',
        component: Workspace,
    },
    {
        path: '/img-to-base64',
        name: 'BASE64',
        component: Base64,
    }, {
        path: '/text-to-speech',
        name: 'TextToSpeech',
        component: TextToSpeech,
    }
]
const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const data = firebase.firebase;
    const user = data.auth().currentUser;
    if (requiresAuth && !user) {
        next("/login")
    } else {
        next();
    }

})

export default router