<template>
  <div class="container-fluid pb-5">
    <router-view />
    <div
      class="container pt-5 pb-5"
      v-for="(blog, index) in BlogDetail"
      :key="index"
    >
      <div class="section-title">
        <h2>Blog Details</h2>
        <p>{{ blog.blogTitle }}</p>
      </div>
      <div class="my-5">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="container-fluid pb-5">
              <img
                class="img-thumbnail mt-2"
                :src="blog.blogImage"
                style="max-height: 400px"
              />
              <h2 style="font-size: 36px; color: #000; padding-top: 2%">
                {{ blog.blogTitle }}
              </h2>
              <p
                v-html="blog.blogDescription"
                style="padding-top: 2%; font-size: 20px; color: #000"
              ></p>
            </div>
            <!-- <img class="seperate col-xl-4" src="/assets/img/separate.png" alt="logo"> -->
            <div class="col-4 d-flex socialContact">
              <div class="row">
                <p class="follow-p">Follow me on social media</p>
                <div class="social-links">
                  <a
                    href="https://www.facebook.com/mehmetali.buber.3/"
                    class="facebook"
                    target="_blank"
                    ><i class="bi bi-facebook"></i
                  ></a>
                  <a
                    href="https://www.instagram.com/mehmetali_buber/"
                    class="instagram"
                    target="_blank"
                    ><i class="bi bi-instagram"></i
                  ></a>
                  <a
                    href="https://www.linkedin.com/in/mehmet-ali-buber-245060193/"
                    class="linkedin"
                    target="_blank"
                    ><i class="bi bi-linkedin"></i
                  ></a>
                  <a
                    href="https://github.com/mehmetalibuber"
                    class="github"
                    target="_blank"
                    ><i class="bi bi-github"></i
                  ></a>
                </div>
                <p class="follow-p mt-2">
                  <span>*</span> You can also take a look at my instagram blog
                  page where I share up-to-date content
                  <a
                    href="https://www.instagram.com/mabcodes/"
                    target="_blank"
                    class="mabcodes"
                    >@mabcodes</a
                  >
                </p>
                <!-- <img class="blogIcon" src="/assets/img/blogicon.png" alt="l" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Scroll />
  </div>
</template>

<script>
import firebase from "../firebase/firebase";
import Scroll from "../components/ScrollToTop.vue";
let db = firebase.db;
export default {
  
  data() {
    return {
      BlogDetail: [],
      ID: "",
    };
  },
  components: {
    Scroll,
  },
  async created() {
    const ID = this.$route.params.blogId;
    //console.log("IDD " + ID);
    await db
      .collection("Blog")
      .doc(ID)
      .get()
      .then((result) => {
        const detay = result.data();
        //console.log("data" + detay);
        this.BlogDetail.push(detay);
      })
      .catch((err) => {
        console.log("detayhata" + err);
      });
  },
  methods: {},
};
</script>

<style>
.follow-p {
  font-size: 1.2em;
}
.follow-p:nth-child(3)::first-letter {
  color: red;
}
.socialContact {
  max-height: 100px;
}
.blogIcon {
  height: 60px;
  width: 60px;
}
.mabcodes {
  color: #ea561b;
}
.mabcodes:hover {
  text-decoration: none;
}
/* .seperate {
  height: 100px;
} */
</style>