<template>
  <div class="preview-section">
    <div class="main-preview">
      <div id="exTab1" class="container-fluid left-input">
        <ul class="nav nav-pills">
          <li class="active"><a href="#html" data-toggle="tab">HTML</a></li>
          <li><a> |</a></li>
          <li><a href="#css" data-toggle="tab"> CSS</a></li>
          <li><a> |</a></li>
          <li><a href="#js" data-toggle="tab">JS</a></li>
        </ul>

        <div class="tab-content clearfix">
          <div class="tab-pane active" id="html">
            <textarea
              name="html"
              id="htmlInput"
              placeholder="
<div class='exp'>
    <h1 class='title'>
    HTML Code Here
    </h1>
</div>"
            ></textarea>
          </div>
          <div class="tab-pane" id="css">
            <textarea
              name="html"
              id="cssInput"
              placeholder="         
/* CSS Code Here */
    .exp .title{
    color: black !important; 
}        
              "
            ></textarea>
          </div>
          <div class="tab-pane" id="js">
            <textarea
              name="html"
              id="jsInput"
              placeholder="JS Code Will Be Active Very Soon!"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="preview">
        <div id="frame"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  async created() {
    this.scripts();
  },
  methods: {
    async scripts() {
      // SCRİPT
      let prevScript = document.createElement("script");
      prevScript.setAttribute("src", "./preview.js");
      document.head.appendChild(prevScript);
    },
  },
};
</script>

<style>
.preview-section {
  margin-top: 1%;
  min-height: 80vh;
}
.preview-section .main-preview {
  display: flex;
  flex-direction: row;
}
.preview-section .main-preview .left-input {
  width: 50%;
}
.preview-section .main-preview .left-input .tab-content {
  color: rgb(0, 0, 0);
  margin-top: 15px;
  padding: 5px 10px;
}
.preview-section .main-preview .left-input .nav-pills {
  margin-top: 20px;
}
.preview-section .main-preview .left-input .nav-pills .active > a {
  color: blueviolet;
}
.preview-section .main-preview .left-input .nav-pills > li > a {
  font-size: 2em;
  color: brown;
  font-weight: 700;
  margin: 5px 10px;
  text-decoration: none;
}
.preview-section .main-preview .left-input textarea {
  width: 600px;
  height: 70vh;
  border: 2px solid brown;
  border-radius: 16px;
  padding: 5px 10px;
}
.preview-section .main-preview .preview {
  width: 50%;
  padding: 2% 20px;
  background-color: #fff;
}
.preview-section .main-preview .preview #frame {
  background-color: #fff;
  color: #000;
  min-height: 80vh;
  padding: 20px 4px;
  border-radius: 12px;
}
.preview-section .main-preview .preview #frame .helloTitle {
  font-weight: 600;
}
.preview-section .main-preview .preview #frame .previewBody {
  padding: 10px 10px;
}

@media (max-width: 1250px) {
  .preview-section {
    margin-top: 1%;
    min-height: auto;
  }
  .preview-section .main-preview {
    display: flex;
    flex-direction: column;
  }
  .preview-section .main-preview .left-input {
    width: 100%;
  }
  .preview-section .main-preview .left-input textarea {
    width: 100%;
    height: 50vh;
    border: 2px solid brown;
    border-radius: 16px;
    padding: 5px 10px;
  }
  .preview-section .main-preview .preview {
    width: 100%;
    padding: 2% 15px;
  }
  .preview-section .main-preview .preview #frame {
    background-color: #fff;
    color: #000;
    height: auto;
    padding: 20px 4px;
    border-radius: 12px;
  }
}
</style>