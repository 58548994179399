<template>
  <div class="flexbox">
    <Board id="board-1">
      <Card id="card-1" draggable="true">
        <router-link to="/text-to-speech" tag="div">
          <h2>Text To Speech</h2>
        </router-link> </Card
      ><Card id="card-1" draggable="true">
        <router-link to="/live-preview" tag="div">
          <h2>LIVE PREVIEW</h2>
        </router-link>
      </Card>
    </Board>
    <Board id="board-2">
      <Card id="card-2" draggable="true">
        <router-link to="/img-to-base64" tag="div">
          <h2>IMG TO BASE64</h2>
        </router-link>
      </Card>
      <Card id="card-1" draggable="true">
        <router-link to="/blog" tag="div">
          <h2>BLOG</h2>
        </router-link>
      </Card></Board
    >

    <!-- 
    <div class="container pt-5 pb-5">
      <div class="section-title">
        <h2>Workspace</h2>
        <p>My WORKSPACE</p>
      </div>
      <div class="my-5 text-center">
        <div class="row">
          <router-link
            class="col-lg-4 col-md-6 col-sm-12"
            to="/blog"
            tag="div"
            style="cursor: pointer"
          >
            <div class="card mb-5 shadow-sm">
              <div class="card-body">
                <div class="card-title">
                  <h2>BLOG</h2>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            class="col-lg-4 col-md-6 col-sm-12"
            to="/live-preview"
            tag="div"
            style="cursor: pointer"
          >
            <div class="card mb-5 shadow-sm">
              <div class="card-body">
                <div class="card-title">
                  <h2>LIVE PREVIEW</h2>
                </div>
              </div>
            </div>
          </router-link>

          <router-link
            class="col-lg-4 col-md-6 col-sm-12"
            to="#"
            tag="div"
            style="cursor: pointer"
          >
            <div class="card mb-5 shadow-sm">
              <div class="card-body">
                <div class="card-title">
                  <h2>IMG TO BASE64</h2>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Board from "../components/Draggable-Board.vue";
import Card from "../components/Draggable-Card.vue";
export default {
  components: { Board, Card },
};
</script>

<style>
.flexbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  padding: 15px;
  background-color: black;
}
.flexbox .board {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  padding: 15px;
}
.flexbox .board .card {
  padding: 25px 25px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 15px;
}
.flexbox .board .card h2 {
  font-size: 2.4em;
  font-weight: 800;
  color: rgb(150, 29, 29);
  margin-bottom: 0 !important;
}
.flexbox .board .card:hover h2 {
  transition: 0.8s;
  color: rgb(204, 112, 7);
}
@media (max-width: 990px) {
  .flexbox {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    padding: 15px;
    flex-direction: column;
  }
  .flexbox .board {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
    padding: 15px;
    height: min-content;
  }

  .flexbox .board .card h2 {
    font-size: 1.4em;
    font-weight: 800;
  }
}
/* .workspace-section {
  height: 100vh;
}
.workspace-section .card-title h2 {
  font-size: 2.4em;
  font-weight: 800;
  color: rgb(150, 29, 29);
}
.workspace-section .card:hover h2 {
  transition: 0.8s;
  color: rgb(204, 112, 7);
} */
</style>