<template>
  <div class="container pt-5 pb-5">
    <div id="contact" class="contact">
      <div class="section-title">
        <h2>Contact</h2>
        <p>Contact Me</p>
      </div>
      <div class="row mt-2">
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-map"></i>
            <h3>My Address</h3>
            <p>Serik, ANTALYA</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-share-alt"></i>
            <h3>Social Profiles</h3>
            <div class="social-links-contact">
              <a
                href="https://www.facebook.com/mehmetali.buber.3/"
                class="facebook"
                target="_blank"
                ><i class="bi bi-facebook"></i
              ></a>
              <a
                href="https://www.instagram.com/mehmetali_buber/"
                class="instagram"
                target="_blank"
                ><i class="bi bi-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/in/mehmet-ali-buber-245060193/"
                class="linkedin"
                target="_blank"
                ><i class="bi bi-linkedin"></i
              ></a>
              <a
                href="https://github.com/mehmetalibuber"
                class="github"
                target="_blank"
                ><i class="bi bi-github"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-envelope"></i>
            <h3>Email Me</h3>
            <p>mehmetalibuber6@gmail.com</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="bx bx-phone-call"></i>
            <h3>WhatsApp</h3>
            <p>+90 531 836 21 46</p>
          </div>
        </div>
      </div>
      <form @submit.prevent="sendMail" class="php-email-form mt-4">
        <div class="row">
          <div class="col-md-6 form-group">
            <input
              type="text"
              name="name"
              class="form-control"
              placeholder="Your Name"
              v-model="mailInfo.name"
              required
            />
          </div>
          <div class="col-md-6 form-group mt-3 mt-md-0">
            <input
              type="email"
              class="form-control"
              name="email"
              placeholder="Your Email"
              v-model="mailInfo.mail"
              required
            />
          </div>
        </div>
        <div class="form-group mt-3">
          <input
            type="text"
            class="form-control"
            name="subject"
            placeholder="Subject"
            v-model="mailInfo.subject"
            required
          />
        </div>
        <div class="form-group mt-3">
          <textarea
            class="form-control"
            name="message"
            rows="5"
            placeholder="Message"
            v-model="mailInfo.message"
            required
          ></textarea>
        </div>
        <!-- <div class="my-3">
          <div class="loading">Loading</div>
          <div class="error-message"></div>
          <div class="sent-message">Your message has been sent. Thank you!</div>
        </div> -->
        <div class="text-center" id="sendButton">
          <button type="submit">Send Message</button>
        </div>
      </form>
    </div>
    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
import emailjs from "emailjs-com";

export default {
  name: "Contact",
  data() {
    return {
      mailInfo: {
        name: "",
        mail: "",
        subject: "",
        message: " ",
      },
    };
  },
  components: {
    Scroll,
  },
  methods: {
    sendMail(e) {
      e.preventDefault();
      try {
        emailjs.sendForm(
          "service_98p09yl",
          "template_qvmiuoc",
          e.target,
          "user_xPWQO0jdM5pxby29iSKYJ",
          {
            name: this.mailInfo.name,
            email: this.mailInfo.mail,
            subject: this.mailInfo.subject,
            message: this.mailInfo.message,
          }
        );
        this.showInfo("Mail Gönderildi");
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.mailInfo.name = "";
      this.mailInfo.mail = "";
      this.mailInfo.subject = "";
      this.mailInfo.message = "";
    },
    showInfo(message) {
      event.preventDefault();
      const a = document.getElementById("sendButton");
      a.innerText = message;
    },
  },
};
</script>

<style>
</style>