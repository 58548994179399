<template>
  <div class="container pt-5 pb-5">
    <div id="portfolio" class="portfolio">
      <div class="section-title">
        <h2>Portfolio</h2>
        <p>My Portfolio</p>
      </div>
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <!--   <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>-->
          </ul>
        </div>
      </div>

      <div class="row portfolio-container">
        <div
          class="col-lg-4 col-md-6 portfolio-item filter-app"
          v-for="(image, index) in Resimler"
          :key="index"
        >
          <div class="portfolio-wrap bg-white">
            <img :src="image.portImage" class="img-thumbnail" alt="" />
            <div class="portfolio-info">
              <h4>Mehmet Ali</h4>
              <p>BUBER</p>
              <div class="portfolio-links">
                <a
                  :href="image.portImage"
                  target="_blank"
                  data-gallery="portfolioGallery"
                  class="portfolio-lightbox"
                  title="Image"
                  ><i class="bx bx-plus"></i
                ></a>
                <a
                  :href="image.portImage"
                  target="_blank"
                  data-gallery="portfolioDetailsGallery"
                  data-glightbox="type: external"
                  class="portfolio-details-lightbox"
                  title="Portfolio Details"
                  ><i class="bx bx-link"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Social />
    </div>
    <Scroll />
  </div>
</template>

<script>
import firebase from "../firebase/firebase";
import Scroll from "../components/ScrollToTop.vue";
import Social from "../components/Social.vue";

let db = firebase.db;

export default {
  data() {
    return {
      Resimler: [],
    };
  },
  components: {
    Scroll,
    Social,
  },
  async created() {
    await db
      .collection("Portfolio")
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const data = doc.data();
          this.Resimler.push(data);
          this.Resimler.sort(function (a, b) {
            return b.portId - a.portId;
          });
        });
      })
      .catch((err) => {
        alert("getBlogData" + err);
      });
  },
};
</script>
 <style>
.wrapper {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.main-div {
  position: relative;
  margin: 10px;
  background-color: transparent;
}

.main-div1::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  z-index: -1;
  -webkit-animation-name: yellow-shadow;
  animation-name: yellow-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.main-div1::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  z-index: -1;
  -webkit-animation-name: cyan-shadow;
  animation-name: cyan-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.main-div2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  z-index: -1;
  -webkit-animation-name: gradient-shadow;
  animation-name: gradient-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.main-div3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  z-index: -1;
  -webkit-animation-name: half-yellow-shadow;
  animation-name: half-yellow-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.main-div3::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  z-index: -1;
  -webkit-animation-name: half-cyan-shadow;
  animation-name: half-cyan-shadow;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

h1 {
  font-size: 50px;
  margin: 0;
  position: relative;
  z-index: 3;
  padding: 20px;
  background-color: #060c1f;
  color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@-webkit-keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

@keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

@-webkit-keyframes gradient-shadow {
  0% {
    -webkit-box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
    box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
  }
  20% {
    -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  }
  40% {
    -webkit-box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
    box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
  }
  60% {
    -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  }
  80% {
    -webkit-box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
    box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
  }
  100% {
    -webkit-box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
    box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
  }
}

@keyframes gradient-shadow {
  0% {
    -webkit-box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
    box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
  }
  20% {
    -webkit-box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
    box-shadow: 0 0 17px 3px #0ff, 0 0 4px 2px #0ff;
  }
  40% {
    -webkit-box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
    box-shadow: 0 0 17px 3px #0f0, 0 0 4px 2px #0f0;
  }
  60% {
    -webkit-box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
    box-shadow: 0 0 17px 3px #ffff01, 0 0 4px 2px #ffff01;
  }
  80% {
    -webkit-box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
    box-shadow: 0 0 17px 3px #f00, 0 0 4px 2px #f00;
  }
  100% {
    -webkit-box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
    box-shadow: 0 0 17px 3px #c586c0, 0 0 4px 2px #c586c0;
  }
}

@-webkit-keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
  16.66% {
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
  }
  32.32% {
    top: 0;
    left: 50%;
    height: 50%;
    width: 50%;
  }
  49.98% {
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
  }
  66.64% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 100%;
  }
  83.3% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 50%;
  }
  100% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
}

@keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
  16.66% {
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
  }
  32.32% {
    top: 0;
    left: 50%;
    height: 50%;
    width: 50%;
  }
  49.98% {
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
  }
  66.64% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 100%;
  }
  83.3% {
    top: 50%;
    left: 0;
    height: 50%;
    width: 50%;
  }
  100% {
    top: 0;
    left: 0;
    height: 50%;
    width: 50%;
  }
}

@-webkit-keyframes half-cyan-shadow {
  0% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
  16.66% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
  }
  32.32% {
    bottom: 0;
    right: 50%;
    height: 50%;
    width: 50%;
  }
  49.98% {
    bottom: 50%;
    right: 50%;
    height: 50%;
    width: 50%;
  }
  66.64% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 100%;
  }
  83.3% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 50%;
  }
  100% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
}

@keyframes half-cyan-shadow {
  0% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
  16.66% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 100%;
  }
  32.32% {
    bottom: 0;
    right: 50%;
    height: 50%;
    width: 50%;
  }
  49.98% {
    bottom: 50%;
    right: 50%;
    height: 50%;
    width: 50%;
  }
  66.64% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 100%;
  }
  83.3% {
    bottom: 50%;
    right: 0;
    height: 50%;
    width: 50%;
  }
  100% {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 50%;
  }
}
</style>