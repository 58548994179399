<template>
  <div class="container">
    <div class="justify-content-center text-center my-5 py-5">
      <div><h2 style="color: black">Text To Speech</h2></div>
      <div>
        <select v-model="selectedVoice" class="voice-selector my-2">
          <option v-for="voice in Voices" :key="voice.name">
            {{ voice.name }}
          </option>
        </select>
      </div>
      <div>
        <textarea
          class="voice-text"
          cols="50"
          rows="8"
          v-model="textToSpeech"
        ></textarea>
      </div>
      <div>
        <input
          type="range"
          v-model="speed"
          ref="speed"
          min="0.5"
          max="3"
          class="my-2"
        />
      </div>
      <div>
        <button class="speak-button" @click="SPEAK">SPEAK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextToSpeech",
  data() {
    return {
      tts: window.speechSynthesis,
      Voices: [],
      selectedVoice: null,
      textToSpeech:
        "Merhaba! Web Sayfama Hoşgeldin. Blog yazıları paylaştığım https://blog.ultof.com web adresini ziyaret etmeyi unutma. Bu arada okuduğum metni düzenleyebilir, dinlediğin sesi değiştirebilirsin. :)",
      speed: 1,
    };
  },
  async created() {
    await this.GET_VOICES().then((voices) => {
      this.Voices = voices;
      this.selectedVoice = voices[0].name;
    });
    this.SPEAK();
  },
  mounted() {
    this.$refs.speed.step = 0.5;
  },
  methods: {
    async GET_VOICES() {
      let intervalID;
      return new Promise((resolve) => {
        intervalID = setInterval(() => {
          if (this.tts.getVoices().length > 0) {
            resolve(this.tts.getVoices());
            clearInterval(intervalID);
          }
        }, 10);
      });
    },
    SPEAK() {
      let toSpeak = new SpeechSynthesisUtterance(this.textToSpeech);
      toSpeak.voice =
        this.Voices.find((v) => v.name == this.selectedVoice) || null;
      toSpeak.rate = this.speed;
      // toSpeak.onboundary = this.ON_BOUNDARY;
      this.tts.speak(toSpeak);
    },
    // async ON_BOUNDARY(event) {
    //   const spokenWord = await event.utterance.text.substr(
    //     event.charIndex,
    //     event.charLenght
    //   );

    //   const wordIndex = this.textToSpeech
    //     .split(" ")
    //     .findIndex((el) => el == spokenWord);
    //   this.$refs[`spoken_word_${wordIndex}`][0]
    //   .classList.add("spoken_word");
    // },
  },
};
</script>

<style>
.spoken_word {
  color: red;
}
.voice-selector {
  background-color: #d2ec3b;
  padding: 5px 8px;
  border: 2px solid #b8d610;
  border-radius: 6px;
  width: 100%;
  color: black;
}
.voice-text {
  background-color: transparent;
  padding: 5px 8px;
  border: 2px solid #b8d610;
  border-radius: 6px;
  width: 100%;
}
.speak-button {
  border: 2px solid #b8d610;
  padding: 5px 40px;
  background-color: transparent;
  border-radius: 4px;
  color: black;
}
</style>
