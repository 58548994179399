<template>
  <div class="container-fluid justify-content-center home-page">
    <div class="row p-5 justify-content-center align-items-center bg-black">
      <div class="col-lg-6">
        <div
          class="home-about-content"
          v-scroll-reveal="{
            desktop: true,
            mobile: false,
            origin: 'top',
            distance: '160px',
            reset: true,
            duration: 2000,
          }"
        >
          <h1>Hi! I am Mehmet ALi BUBER 👋</h1>
          <h4>My Nickname is: <strong> mabcodes </strong></h4>
          <p>
            Corporate Web Services, Carefully Coded Themes, Mobile Compatible
            Interfaces, SEO Infrastructure, Wordpress Web Solutions, Blog Pages,
            Portfolio Tools etc. offers services.
          </p>
          <ul>
            <li>
              🔭&nbsp;&nbsp;I’m currently working on web applications
              development.
            </li>
            <li>🌱&nbsp;&nbsp;I’m currently learning deep JavaScript.</li>
            <li>
              👯&nbsp;&nbsp;Some skills: HTML5 ⚡ CSS3 ⚡ JAVASCRIPT ⚡ VUE.JS
              ⚡ NODE.JS ⚡ WORDPRESS ⚡ ASP.NET / CORE MVC ⚡ C#...
            </li>
            <li>
              💬&nbsp;&nbsp;Freelance job offers on
              <a href="https://ultof.com" target="_blank" title="ultof.com">
                https://ultof.com
              </a>
            </li>
            <li>
              📫&nbsp;&nbsp;You can also check out my blog posts on
              <a
                href=" https://blog.ultof.com/"
                target="_blank"
                title="blog.ultof.com"
              >
                https://blog.ultof.com/</a
              >
            </li>
          </ul>

          <!-- <h2
            class="homeDescH2"
            contenteditable
            spellcheck="false"
            v-scroll-reveal="{
              desktop: true,
              mobile: false,
              origin: 'top',
              distance: '160px',
              reset: true,
              duration: 2000,
            }"
          >
            I am a Front-end web developer
          </h2> -->
        </div>

        <div
          class="social-links"
          v-scroll-reveal="{
            desktop: true,
            mobile: false,
            origin: 'bottom',
            distance: '160px',
            reset: true,
            duration: 2000,
          }"
        >
          <a
            href="https://www.facebook.com/mehmetali.buber.3/"
            class="facebook"
            target="_blank"
            ><i class="bi bi-facebook"></i
          ></a>
          <a
            href="https://www.instagram.com/mehmetali_buber/"
            class="instagram"
            target="_blank"
            ><i class="bi bi-instagram"></i
          ></a>
          <a
            href="https://www.linkedin.com/in/mehmet-ali-buber-245060193/"
            class="linkedin"
            target="_blank"
            ><i class="bi bi-linkedin"></i
          ></a>
          <a
            href="https://github.com/mehmetalibuber"
            class="github"
            target="_blank"
            ><i class="bi bi-github"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-6 daily-img">
        <div class="">
          <a
            href="https://app.daily.dev/mabcodes"
            class="d-flex justify-content-center"
            v-scroll-reveal="{
              desktop: true,
              mobile: false,
              origin: 'bottom',
              distance: '200px',
              reset: true,
              duration: 6000,
            }"
            title="MEHMET ALİ BÜBER's Dev Card"
            ><img
              src="https://api.daily.dev/devcards/b6ecc804e8ab48f09a88bebecaffa9b7.png?r=oeb"
              width="400"
              alt="MEHMET ALİ BÜBER's Dev Card"
          /></a>
        </div>
      </div>
    </div>
    <a href="https://api.whatsapp.com/send?phone=905318362146" target="_blank">
      <i class="fab fa-whatsapp wp-icon"></i>
    </a>
  </div>
</template>
<script>
export default {
  name: "Home",
};
</script>