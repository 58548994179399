<template>
  <div class="base64-main">
    <div class="container-fluid col col-12 d-flex mt-5 mb-5">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <label for="base64-input">Please Select An Image</label>
        <input @change="IMG_CHANGE()" type="file" id="base64-input" />
        <textarea rows="18" cols="" id="base64-textarea"></textarea>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src="" alt="Please Select An Image" id="base64-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Base64",
  methods: {
    IMG_CHANGE() {
      const file = document.getElementById("base64-input").files[0];
      console.log(file);
      let reader = new FileReader(file);
      reader.readAsDataURL(file);

      reader.onload = function (fileLoadEvent) {
        let base64 = fileLoadEvent.target.result;
        document.getElementById("base64-textarea").value = base64;
        let img = document.getElementById("base64-img");
        img.setAttribute("src", base64);
      };
    },
  },
};
</script>

<style>
.base64-main {
}
.base64-main input {
  background-color: rgb(141, 6, 6);
  border-radius: 12px;
  height: auto;
  padding: 10px;
  color: #fff;
}

.base64-main .container-fluid {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
}

.base64-main .container-fluid div:first-child {
  display: flex;
  flex-direction: column;
}

.base64-main .container-fluid div:last-child {
  margin: 5%;
}

.base64-main textarea {
  margin-top: 20px;
  border-radius: 12px;
  border: 2px solid;
}

.base64-main img {
  height: 400px;
  width: 500px;
  border: 0px solid;
  border-radius: 12px;
  padding: 0px;
}
@media (max-width: 990px) {
  .base64-main input {
    background-color: rgb(141, 6, 6);
    border-radius: 12px;
    height: auto;
    padding: 10px;
    color: #fff;
  }

  .base64-main .container-fluid {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  .base64-main .container-fluid div:first-child {
    display: flex;
    flex-direction: column;
  }

  .base64-main .container-fluid div:last-child {
    margin: 5%;
  }

  .base64-main textarea {
    margin-top: 20px;
    border-radius: 12px;
    border: 2px solid;
  }

  .base64-main img {
    height: 300px;
    width: 90%;
    border-radius: 12px;
  }
}
</style>