// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAMcvEbBrXvbLiiPW3WHz-KciviDXvqiiU",
    authDomain: "mehmetalibubercom.firebaseapp.com",
    projectId: "mehmetalibubercom",
    storageBucket: "mehmetalibubercom.appspot.com",
    messagingSenderId: "772588910759",
    appId: "1:772588910759:web:014740b43d7d7790134382",
    measurementId: "G-H4M3G2DXXL"
  };
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export default{
    firebase,
    auth,
    db,
    storage
}