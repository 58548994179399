<template>
     <div class="footer">
    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/personal-free-resume-bootstrap-template/  -->
   Developed by&nbsp;<a href="https://mehmetalibuber.com">Mehmet Ali BUBER.</a>
   <a class="footerSecondA" style="color:#fff">&nbsp;
   All Rights Reserved! &nbsp;Total Visitor :&nbsp;{{visitor}}</a>
  </div> 
</template>

<script>
export default {
  data(){
    return({
      visitor:"",
    })
  },

created(){
  this.visitorCounter();

},
methods:{
    //for cretion api url https://api.countapi.xyz/create?namespace=mehmetalibuber&key=mywebsite&value=0
    async visitorCounter(){
      fetch('https://api.countapi.xyz/update/mehmetalibuber/mywebsite/?amount=1')
      .then(resp => resp.json()).then(resp => {
        this.visitor = resp.value;
      })
    },
},
}
</script>

<style>

</style>