<template>
  <div
    class="tagembed-container"
    style="width: 100%; height: 100%; overflow: auto"
  >
    <div
      class="tagembed-socialwall"
      data-wall-id="63722"
      view-url="https://widget.tagembed.com/63722?view"
    ></div>
  </div>
</template>

<script>
export default {
  async created() {
    await this.scripts();
  },

  methods: {
    async scripts() {
      let insta = document.createElement("script");
      insta.setAttribute("src", "./instaFeed.js");
      document.body.appendChild(insta);
    },
  },
};
</script>

<style>
</style>