<template>
  <div class="container pt-5 pb-5">
    <div id="about" class="about">
      <div class="section-title">
        <h2>About</h2>
        <p>Learn more about me</p>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-right">
          <img src="assets/img/websiteLogo.png" class="img-fluid p-5" alt="" />
        </div>
        <div
          class="col-lg-8 pt-4 pt-lg-0 aboutContent mt-5"
          data-aos="fade-left"
        >
          <h3>Front-end Web Developer &amp; Digital Content Creator</h3>
          <div class="row mt-4">
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <strong>Birthday:</strong> <span>23 June 1999</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>Website:</strong>
                  <span>www.mehmetalibuber.com</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>Phone:</strong>
                  <span>+90 531 836 21 46</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>City:</strong>
                  <span>Antalya, TURKEY</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>Age:</strong>
                  <span>23</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>Degree:</strong>
                  <span>License</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i> <strong>E mail:</strong>
                  <span>mehmetalibuber6@gmail.com</span>
                </li>
                <li>
                  <i class="bi bi-chevron-right"></i>
                  <strong>Freelance:</strong> <span>Available</span>
                </li>
              </ul>
            </div>
          </div>
          <p class="mt-3">
            I have been designing for digital environments for 5 years and
            developing website themes and codes for three years. For the last 1
            year, I have been developing web 3.0 software in the fields of
            blockchain, nft, smart contract.
          </p>
          <a @click="CV_MODAL" class="btn btn-danger" id="cv">Download My CV</a>
        </div>
      </div>
    </div>
    <!-- End About Me -->

    <!-- CV MODAL START -->
    <div v-if="show_cv_modal == true" class="cv_modal container">
      <i class="fa fa-close cv-modal-close" @click="CV_MODAL_CLOSE"></i>
      <form @submit.prevent="CV_FORM">
        <div class="form-group">
          <label for="company">Your Name or Company</label>
          <input
            class="form-control"
            type="text"
            id="company"
            v-model="cv_infos.cv_name"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">Your E-Mail Address</label>
          <input
            class="form-control"
            type="email"
            id="email"
            v-model="cv_infos.cv_email"
            required
          />
        </div>
        <button type="submit" class="btn">Download CV &#127746;</button>
      </form>
    </div>
    <!-- CV MODAL END -->

    <!-- ======= Skills  ======= -->
    <div class="skills container">
      <div class="section-title mt-3">
        <h2>Skills</h2>
      </div>
      <div class="row skills-content">
        <div class="col-lg-6">
          <div class="progress">
            <span class="skill">HTML 5<i class="val">98%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="98"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 98%"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">CSS 3 | SCSS <i class="val">92%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="92"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 92%"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">JavaScript <i class="val">90%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 90%"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">bootstrap<i class="val">98%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="98"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 98%"
              ></div>
            </div>
          </div>
          <div class="progress">
            <span class="skill"
              >Search Engine Optimization <i class="val">92%</i></span
            >
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="97"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 92%"
              ></div>
            </div>
          </div>
          <div class="progress">
            <span class="skill">Photoshop <i class="val">75%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 75%"
              ></div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="progress">
            <span class="skill">Design <i class="val">93%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 93%"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">WordPress <i class="val">98%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="98"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 98%"
              ></div>
            </div>
          </div>
          <div class="progress">
            <span class="skill">Vue.js<i class="val">95%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="86"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 95%"
              ></div>
            </div>
          </div>
          <div class="progress">
            <span class="skill"
              >Node.js | Express.JS <i class="val">75%</i></span
            >
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 75%"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">RESTful API | JSON<i class="val">95%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="95"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 95%"
              ></div>
            </div>
          </div>
          <div class="progress">
            <span class="skill"
              >Web 3.0 | blockchain | NFT's<i class="val">85%</i></span
            >
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="85"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 85%"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Skills -->

    <!-- ======= frameworks ======= -->
    <div class="interests container">
      <div class="section-title">
        <h2>Frameworks</h2>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4">
          <div class="icon-box">
            <i class="ri-store-line" style="color: #ffbb2c"></i>
            <h3>Node.Js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="ri-bar-chart-box-line" style="color: #5578ff"></i>
            <h3>Vue.Js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="ri-price-tag-2-line" style="color: #5578ff"></i>
            <h3>Express.JS</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- End frameworks -->

    <!-- ======= Libraries ======= -->
    <div class="interests container mt-4">
      <div class="section-title">
        <h2>Libraries</h2>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4">
          <div class="icon-box">
            <i class="ri-store-line" style="color: #ffbb2c"></i>
            <h3>jQuery</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="ri-bar-chart-box-line" style="color: #5578ff"></i>
            <h3>ScrollReveal.Js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
          <div class="icon-box">
            <i class="ri-database-2-line" style="color: #e361ff"></i>
            <h3>D3.js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div class="icon-box">
            <i class="ri-calendar-todo-line" style="color: #e80368"></i>
            <h3>Anime.js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-database-2-line" style="color: #47aeff"></i>
            <h3>Animate On Scroll (AOS)</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-gradienter-line" style="color: #ffa76e"></i>
            <h3>Chart.js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-file-list-3-line" style="color: #11dbcf"></i>
            <h3>Popper.js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-price-tag-2-line" style="color: #4233ff"></i>
            <h3>Three.js</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-anchor-line" style="color: #b2904f"></i>
            <h3>Email.JS</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 mt-4">
          <div class="icon-box">
            <i class="ri-disc-line" style="color: #b20969"></i>
            <h3>typed.js</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- End Libraries -->

    <Scroll />
  </div>
</template>

<script>
import Scroll from "../components/ScrollToTop.vue";
export default {
  name: "About",
  components: {
    Scroll,
  },
  data() {
    return {
      show_cv_modal: false,
      cv_infos: {
        cv_name: "",
        cv_email: "",
      },
      cv_url:
        "https://firebasestorage.googleapis.com/v0/b/mehmetalibubercom.appspot.com/o/mehmetalibuber-cv.pdf?alt=media&token=6eb62803-f033-4930-9945-bbf2fdbb9512",
    };
  },
  methods: {
    CV_MODAL() {
      this.show_cv_modal = true;
    },
    async CV_FORM() {
      await this.$store
        .dispatch("DOWNLOADED_CV", this.cv_infos)
        .then(() => {
          this.cv_infos = {};
          this.show_cv_modal = false;
          window.open(this.cv_url, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CV_MODAL_CLOSE() {
      this.show_cv_modal = false;
    },
  },
};
</script>

<style>
.cv_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  background-color: rgb(212, 38, 38);
  top: 30%;
  height: 50%;
  border-radius: 16px;
}
.cv_modal .cv-modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #fff;
  font-size: 3em;
}
.cv_modal form {
}
.cv_modal form div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cv_modal input {
  background-color: transparent;
  margin-top: 2%;
  border: 2px solid #fff;
  color: #fff;
  width: 110%;
}
.cv_modal label {
  font-size: 1.2em;
  font-style: italic;
}
.cv_modal button {
  width: 100%;
  background-color: #fff;
}
@media (max-width: 990px) {
  .cv_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    background-color: rgb(212, 38, 38);
    top: 20%;
    height: 50%;
    border-radius: 16px;
    width: 90% !important;
  }
  .cv_modal .cv-modal-close {
    position: absolute;
    right: 30px;
    top: 30px;
    color: #fff;
    font-size: 2.2em;
  }
  .cv_modal form {
  }
  .cv_modal form div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cv_modal input {
    background-color: transparent;
    margin-top: 2%;
    border: 2px solid #fff;
    color: #fff;
    width: 110%;
  }
  .cv_modal label {
    font-size: 1.1em;
    font-style: italic;
  }
  .cv_modal button {
    width: 100%;
    background-color: #fff;
  }
}
</style>